<template>
  <Row class="px-1">
    <i-col span="24" class="align-justify">
      <Tabs>
        <TabPane label="设置小程序中推荐位信息">
          <Row :gutter="32">
            <i-col span="24">
              <Row :gutter="32">
                <i-col span="16">
                  <Row :gutter="32">
                    <Form>
                      <i-col span="4">
                        <FormItem label="图例" label-position="top">
                          <div class="img-box">
                            <div class="img-wrap" style="text-align: center;">
                              <viewer :images="imgList">
                                <img
                                  v-for="(src,index) in imgList"
                                  :src="src"
                                  :key="index"
                                  style="width:130px;"
                                />
                              </viewer>
                            </div>
                          </div>
                        </FormItem>
                      </i-col>
                      <i-Col span="8" class="align-justify">
                        <FormItem label="素材库说明" label-position="top">
                          <Input
                            size="large"
                            v-model="recommendInfo.RecommendContent"
                            maxlength="100"
                            show-word-limit
                            placeholder="请输入说明..."
                          />
                        </FormItem>
                      </i-Col>
                      <i-Col span="6" class="align-justify">
                        <FormItem label="选择页面" label-position="left">
                          <Select
                            size="large"
                            class="mr-1"
                            v-model="recommendInfo.SetPath"
                            @on-select="selectPath1"
                          >
                            <Option
                              v-for="item in pathList"
                              :value="item.value"
                              :key="item.value"
                            >{{ item.label }}</Option>
                          </Select>
                        </FormItem>
                      </i-Col>
                      <i-Col span="6" class="align-justify">
                        <FormItem label="页面指定参数值" label-position="left">
                          <Select
                            size="large"
                            class="mr-1"
                            v-model="recommendInfo.SetId"
                            filterable
                            clearable
                            @on-select="selectContent1"
                          >
                            <Option
                              v-for="item in contentList"
                              :value="item.value"
                              :key="item.value"
                            >{{ item.label }}</Option>
                          </Select>
                        </FormItem>
                      </i-Col>
                      <i-col span="4"></i-col>
                      <i-col span="20">
                        <Upload
                          type="drag"
                          :format="['jpg','jpeg','png']"
                          :on-success="handleSuccess"
                          :max-size="2048"
                          :on-format-error="handleFormatError"
                          :on-exceeded-size="handleMaxSize"
                          action="https://swapi.hxypsy.cn/api/Activity/UploadImage"
                        >
                          <div style="padding: 20px 0">
                            <Icon type="ios-cloud-upload" size="80" style="color: #3399ff"></Icon>
                            <p>请上传你想设置的封面图片（为呈现良好的显示效果，建议宽高比例为3:1），点击左侧图片可放大查看图例</p>
                          </div>
                        </Upload>
                      </i-col>
                      <i-Col span="20" class="align-justify">
                        <FormItem>
                          <Poptip confirm title="是否添加推荐位信息素材?" @on-ok="setrecommended">
                            <Button type="primary">创建</Button>
                          </Poptip>
                        </FormItem>
                      </i-Col>
                    </Form>
                  </Row>
                </i-col>
              </Row>
            </i-col>
            <i-col span="24">
              <Alert>推荐位信息素材库</Alert>
              <Scroll height="400">
                <Table
                  border
                  stripe
                  ref="recommended"
                  :columns="tColumns1"
                  :data="tData1"
                  class="mt-1"
                >
                  <div slot-scope="{ row }" slot="action">
                    <Button
                      type="primary"
                      size="small"
                      style="margin-right: 0.25rem"
                      @click="kpi1(row)"
                    >点击率</Button>
                    <Button
                      type="warning"
                      size="small"
                      style="margin-right: 0.25rem"
                      @click="remove1(row)"
                    >删除</Button>
                    <Button size="small" @click="checked1(row)">选用</Button>
                  </div>
                </Table>
              </Scroll>
              <Page
                :total="recordTotal"
                :page-size="50"
                show-total
                @on-change="changePage"
                @on-page-size-change="changeSize"
                show-elevator
                class="p-1 align-right"
              />
            </i-col>
          </Row>
        </TabPane>
        <TabPane label="设置小程序中轮播位信息">
          <Row :gutter="32">
            <i-col span="24">
              <Row :gutter="32">
                <i-col span="24">
                  <Row :gutter="32">
                    <Form>
                      <i-col span="2">
                        <FormItem label="图例" label-position="top">
                          <div class="img-box">
                            <div class="img-wrap" style="text-align: center;">
                              <viewer :images="imgList">
                                <img
                                  v-for="(src,index) in imgList"
                                  :src="src"
                                  :key="index"
                                  style="width:130px;"
                                />
                              </viewer>
                            </div>
                          </div>
                        </FormItem>
                      </i-col>
                      <i-col span="12">
                        <FormItem label="效果" label-position="top"></FormItem>
                      </i-col>
                      <i-col span="24">
                        <Table
                          border
                          stripe
                          ref="recommended"
                          :columns="tColumns2"
                          :data="tData2"
                          class="mt-1"
                        >
                          <div slot-scope="{ row }" slot="action">
                            <Button
                              type="primary"
                              size="small"
                              style="margin-right: 0.25rem"
                              @click="kpi2(row)"
                            >点击率</Button>
                            <Button
                              size="small"
                              style="margin-right: 0.25rem"
                              @click="update2(row)"
                            >更改</Button>
                          </div>
                        </Table>
                      </i-col>
                    </Form>
                  </Row>
                </i-col>
                <i-col span="24"></i-col>
              </Row>
            </i-col>
          </Row>
        </TabPane>
        <TabPane label="设置小程序中弹窗信息"></TabPane>
        <TabPane label="动态生成小程序码"></TabPane>
        <TabPane label="小程序个人主页菜单设置"></TabPane>
      </Tabs>
    </i-col>
  </Row>
</template>
<script>
export default {
  name: "mpsetting",
  data() {
    return {
      carouselIndex: 0,
      recommendInfo: {},
      recordTotal: 0,
      recommendDesc: "",
      pathList: [
        { value: "1", label: "量表页面" },
        { value: "2", label: "活动页面" },
        { value: "3", label: "问卷页面" },
        { value: "4", label: "量表套餐页面" },
        { value: "5", label: "咨询师页面" },
      ],
      contentList: [],
      recommendToPath: "",
      recommendToId: "",
      imgList: ["https://swapi.hxypsy.cn/Uploads/static/setActivity.png"],
      imgList1: ["https://swapi.hxypsy.cn/Uploads/static/setCarousel.png"],
      imgList2: ["https://swapi.hxypsy.cn/Uploads/static/setPopup.jpg"],
      tColumns1: [
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "设置图片",
          key: "setImage",
          minWidth: 80,
          render: (h, params) => {
            let devicesArr = [];
            let photo = [];
            photo.push("https://swapi.hxypsy.cn" + params.row.setImage);
            devicesArr.push(
              h(
                "viewer",
                {
                  props: {
                    images: photo,
                  },
                },
                [
                  h("img", {
                    attrs: {
                      src: photo[0],
                      style: "width: 60px;height:60px;margin-right:5px",
                    },
                  }),
                ]
              )
            );
            return h("div", devicesArr);
          },
        },
        {
          title: "设置信息说明",
          key: "recommendContent",
          minWidth: 200,
          ellipsis: true,
        },
        {
          title: "设置页面",
          key: "setPath",
          minWidth: 200,
          render: (h, params) => {
            const row = params.row;
            const color =
              row.setPath === "pages/measurementDetails/measurementDetails"
                ? "primary"
                : row.setPath === "pages/activityDetail/activityDetail"
                ? "success"
                : row.setPath === "pages/surveyDetail/surveyDetail"
                ? "error"
                : row.setPath === "pages/packagedetail/packagedetail"
                ? "#17233d"
                : "warning";
            const text =
              row.setPath === "pages/measurementDetails/measurementDetails"
                ? "量表页面"
                : row.setPath === "pages/activityDetail/activityDetail"
                ? "活动页面"
                : row.setPath === "pages/surveyDetail/surveyDetail"
                ? "问卷页面"
                : row.setPath === "pages/packagedetail/packagedetail"
                ? "套餐页面"
                : "咨询师页面";
            return h(
              "Tag",
              {
                props: {
                  type: "dot",
                  color: color,
                },
              },
              text
            );
          },
        },
        {
          title: "指向详情",
          key: "setName",
          minWidth: 200,
          ellipsis: true,
        },
        {
          title: "设置时间",
          key: "setTime",
          minWidth: 120,
          ellipsis: true,
          render: (h, params) => {
            let dt = this.myjs.subDate(params.row.setTime);
            return h("span", dt);
          },
          sortable: true,
        },
        {
          title: "是否在选",
          key: "checked",
          minWidth: 120,
          render: (h, params) => {
            const row = params.row;
            const color = row.checked === true ? "success" : "warning";
            const text = row.checked === true ? "正在使用" : "闲置";
            return h(
              "Tag",
              {
                props: {
                  type: "dot",
                  color: color,
                },
              },
              text
            );
          },
          filters: [
            {
              label: "在用状态",
              value: true,
            },
            {
              label: "闲置状态",
              value: false,
            },
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            return row.checked === value;
          },
          sortable: true,
        },
        {
          title: "操作",
          slot: "action",
          minWidth: 110,
        },
      ],
      tData1: [],
      tColumns2: [
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "设置图片",
          key: "setImage",
          minWidth: 80,
          render: (h, params) => {
            let devicesArr = [];
            let photo = [];
            photo.push("https://swapi.hxypsy.cn" + params.row.setImage);
            devicesArr.push(
              h(
                "viewer",
                {
                  props: {
                    images: photo,
                  },
                },
                [
                  h("img", {
                    attrs: {
                      src: photo[0],
                      style: "width: 60px;height:60px;margin-right:5px",
                    },
                  }),
                ]
              )
            );
            return h("div", devicesArr);
          },
        },
        {
          title: "设置信息说明",
          key: "recommendContent",
          minWidth: 200,
          ellipsis: true,
        },
        {
          title: "设置页面",
          key: "setPath",
          minWidth: 200,
          render: (h, params) => {
            const row = params.row;
            const color =
              row.setPath === "pages/measurementDetails/measurementDetails"
                ? "primary"
                : row.setPath === "pages/activityDetail/activityDetail"
                ? "success"
                : row.setPath === "pages/surveyDetail/surveyDetail"
                ? "error"
                : row.setPath === "pages/packagedetail/packagedetail"
                ? "#17233d"
                : "warning";
            const text =
              row.setPath === "pages/measurementDetails/measurementDetails"
                ? "量表页面"
                : row.setPath === "pages/activityDetail/activityDetail"
                ? "活动页面"
                : row.setPath === "pages/surveyDetail/surveyDetail"
                ? "问卷页面"
                : row.setPath === "pages/packagedetail/packagedetail"
                ? "套餐页面"
                : "咨询师页面";
            return h(
              "Tag",
              {
                props: {
                  type: "dot",
                  color: color,
                },
              },
              text
            );
          },
        },
        {
          title: "指向详情",
          key: "setName",
          minWidth: 200,
          ellipsis: true,
        },
        {
          title: "设置时间",
          key: "setTime",
          minWidth: 120,
          ellipsis: true,
          render: (h, params) => {
            let dt = this.myjs.subDate(params.row.setTime);
            return h("span", dt);
          },
          sortable: true,
        },
        {
          title: "操作",
          slot: "action",
          minWidth: 110,
        },
      ],
      tData2: [],
      tColumns3: [],
      tData3: [],
      pageSize: 50,
      pageNum: 1,
    };
  },
  mounted() {
    this.getRecommendList();
  },
  methods: {
    handleSuccess(res, file) {
      this.recommendInfo.SetImage = res;
    },
    handleFormatError(file) {
      this.$Notice.error({
        title: "错误",
        desc: "文件 " + file.name + " 格式错误, 请选择 jpg 或者 png.",
      });
    },
    handleMaxSize(file) {
      this.$Notice.error({
        title: "错误",
        desc: "文件 " + file.name + " 过大, 请选择不超过2M的文件.",
      });
    },
    changeSize(e) {
      this.pageSize = e;
      this.getRecommendList();
    },
    changePage(e) {
      this.pageNum = e;
      this.getRecommendList();
    },
    setrecommended() {
      if (
        this.recommendInfo.RecommendContent == undefined ||
        this.recommendInfo.SetPath == undefined ||
        this.recommendInfo.SetId == undefined ||
        this.recommendInfo.SetImage == undefined
      ) {
        this.$Message.error("有未上传完整的项");
        return;
      }
      console.log(this.recommendInfo);
      let data = this.recommendInfo;
      this.$post("General/AddRecommend", {
        SetImage: data.SetImage,
        RecommendContent: data.RecommendContent,
        SetPath: data.SetPath,
        SetId: data.SetId,
        SetName: data.SetName,
      }).then((res) => {
        if (res.data.code) {
          this.$Message.success("创建成功");
          this.recommendInfo = {};
          this.getRecommendList();
        }
      });
    },
    selectPath1(e) {
      console.log(e);
      this.$get("General/GetPathContentList", {
        pathType: e.value,
      }).then((res) => {
        this.contentList = res.data.responseList;
      });
    },
    selectContent1(e) {
      this.recommendInfo.SetName = e.label;
    },
    getRecommendList() {
      this.$get("General/GetRecommendList", {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then((res) => {
        this.recordTotal = res.data.total;
        this.tData1 = res.data.responseList;
      });
    },
    kpi1(row) {},
    checked1(row) {},
    remove1(row) {},
  },
};
</script>

<style lang="less" scoped="scoped">
.demo-img {
  width: 100%;
}
</style>